/**
 * Menu
 */

import LangButton from 'components/LangButton';
import MenuUl from 'components/MenuUl';
import HeaderLogo from 'components/PageHeader/HeaderLogo';
import React, { useEffect, useRef, useState } from 'react';
import Icon from 'components/Icon';
import { Link } from 'types/global';

interface Props {
	/** Pass an URL if the logo should be linked  */
	logoLink?: string;

	/** Logo image subtitle */
	logoSubtitle?: string;

	logoAriaLabel?: string;

	/** Main menu links */
	menu?: any;

	/** Language options */
	lang: Array<Link>;
	/** Language btn style */
	langBtnStyle: string;

	isOpen: boolean;

	/** Callback method when the menu closes */
	setMenuIsOpen: (value: React.SetStateAction<boolean>) => void;

	isMobile?: boolean;
}

/** Main description for this component. */
const Menu: React.FC<Props> = ({
	menu,
	lang,
	isOpen,
	setMenuIsOpen,
	langBtnStyle,
	logoLink,
	logoAriaLabel,
	logoSubtitle,
	isMobile,
}) => {
	const logoRef = useRef<any>(null);
	const headerRef = useRef<any>(null);
	const lastChildRef = useRef<any>(null);
	const focusOnLastEle = useRef<any>(null);
	const [lastChildObj, setLastChildObj] = useState<any>();

	useEffect(() => {
		// hide the body scrollbar if menu is open
		var style = document.createElement('style');

		if (isOpen) {
			style.innerHTML = `body::-webkit-scrollbar {display: none;}`;
			document.head.appendChild(style);
			// focus on the Logo when menu is opening
			headerRef.current.focus();
		} else {
			style.innerHTML = `body::-webkit-scrollbar {display: block;}`;
			document.head.appendChild(style);
		}
		getLastMenuItem(menu.menuItems);
	}, [isOpen]);

	// Callback function to be called by the Grandchild component
	const setLastLiRef = (ref: React.RefObject<any>) => {
		lastChildRef.current = ref;
	};

	useEffect(() => {
		if (lastChildRef.current) {
			if (
				lastChildRef.current.current &&
				lastChildRef.current.current.tagName === 'BUTTON'
			) {
				focusOnLastEle.current = lastChildRef.current;
			} else {
				focusOnLastEle.current = lastChildRef.current;
			}
		}
	}, [setLastLiRef]);

	function getLastMenuItem(menu: any): any {
		const menuItems = menu;
		const lastMenuItem =
			menuItems && menuItems.length ? menuItems[menuItems.length - 1] : null;

		if (
			lastMenuItem &&
			lastMenuItem.children &&
			lastMenuItem.children.length > 0
		) {
			return getLastMenuItem(lastMenuItem.children);
		} else {
			setLastChildObj(lastMenuItem);
		}
	}

	const handleEscKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
		switch (event.key) {
			case 'Escape':
				event.preventDefault();
				event.stopPropagation();
				setMenuIsOpen(false);
				break;
			default:
				break;
		}
	};

	const handleContainerKeyPress = (
		event: React.KeyboardEvent<HTMLDivElement>
	) => {
		switch (event.key) {
			case 'Tab':
				if (
					(event.shiftKey && document.activeElement === logoRef.current) ||
					(event.shiftKey && document.activeElement === headerRef.current)
				) {
					// do focus on the last element of the list or button "+"

					/* if (focusOnLastEle.current.current && document.activeElement?.tagName === 'BUTTON' &&
					focusOnLastEle.current.current.id === "btnMinus"
					) {
						if (lastChildObj.text === document.activeElement?.textContent) {
							// Tab from the last item should focus on the logo
							logoRef.current.focus();
							event.preventDefault();
						}
					} */
					focusOnLastEle.current.current.focus();
					event.preventDefault();
					break;
				} else if (
					event.shiftKey &&
					document.activeElement === focusOnLastEle.current.current
				) {
					// do not remove this empty code
					// Shift+Tab within the list should focus on the previous item
				} else if (
					document.activeElement === focusOnLastEle.current.current &&
					document.activeElement?.tagName !== 'BUTTON'
				) {
					// Tab from the last item should focus on the logo
					logoRef.current.focus();
					event.preventDefault();

					break;
				} else if (
					document.activeElement === focusOnLastEle.current.current &&
					document.activeElement?.tagName === 'BUTTON'
				) {
					if (document.activeElement.id === 'btnPlus') {
						logoRef.current.focus();
						event.preventDefault();
					}
				} else if (document.activeElement !== focusOnLastEle.current.current) {
					if (lastChildObj.text === document.activeElement?.textContent) {
						// Tab from the last item should focus on the logo
						logoRef.current.focus();
						event.preventDefault();
					}
				}

			default:
				break;
		}
	};

	const handleContainerKeyUpPress = (
		e: React.KeyboardEvent<HTMLDivElement>
	) => {
		// Block all keyup
		e.stopPropagation();
	};

	return (
		<>
			{isOpen && (
				<nav
					onKeyDown={handleEscKeyPress}
					tabIndex={-1}
					className={
						'no-scrollbar fixed overflow-hidden z-1000 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out ' +
						(isOpen
							? 'transition-opacity opacity-100 duration-500 translate-x-0'
							: 'transition-all delay-500 opacity-0 translate-x-full')
					}
					aria-label={
						menu?.closeText?.startsWith('Stäng') ? 'Huvudmeny' : 'Main Menu'
					}
				>
					<div
						className={
							'w-screen max-w-lg right-0 absolute bg-white h-full shadow-xl delay-500 duration-500 ease-in-out transition-all transform ' +
							(isOpen ? 'translate-x-0' : 'translate-x-full')
						}
					>
						<div
							onKeyDown={handleContainerKeyPress}
							onKeyUp={handleContainerKeyUpPress}
							className="relative w-screen max-w-lg flex flex-col menu-height overflow-x-hidden"
						>
							<header
								ref={headerRef}
								tabIndex={-1}
								className="h-menu flex justify-between items-center gap-2 sm:gap-4 p-6 shadow-md focus-visible:outline-none outline-none z-1"
							>
								<HeaderLogo
									ref={logoRef}
									linkTo={logoLink}
									className="mt-0"
									subtitle={logoSubtitle}
									setMenuIsOpen={setMenuIsOpen}
									isLogoInMenu
									isMobile={isMobile}
									ariaLabel={logoAriaLabel}
									isInMenu
								/>

								<div className="flex items-center gap-2 sm:gap-4">
									{lang?.map((item, index) => (
										<LangButton
											key={item.text + index.toString()}
											item={item}
											setMenuIsOpen={setMenuIsOpen}
										/>
									))}
									<button
										className={`${langBtnStyle} flex items-center`}
										onClick={() => setMenuIsOpen(false)}
										aria-label={
											menu?.closeText?.startsWith('Stäng')
												? 'Stäng meny'
												: 'Close menu'
										}
									>
										{menu.closeText}
										<Icon
											size={99}
											icon="remove"
											aria-hidden="true"
											className="ml-2"
										/>
									</button>
								</div>
							</header>

							<div className="px-6 menu-scrollable h-full overflow-y-scroll">
								<MenuUl
									level={1}
									menuItems={menu.menuItems}
									setMenuIsOpen={setMenuIsOpen}
									setLastChildRef={setLastLiRef}
								/>
							</div>
						</div>
					</div>
					<div
						tabIndex={-1}
						className="w-screen h-full cursor-pointer"
						onClick={() => {
							setMenuIsOpen(false);
						}}
					/>
				</nav>
			)}
		</>
	);
};

export default Menu;
