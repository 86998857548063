/**
 * RichText
 */

import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectEpi } from 'store/modules/epi';

interface Props {
	className?: string;
}

/** Richtext components which handles links. */
const RichText: React.FC<Props> = ({ className, children }) => {
	const history = useHistory();
	//const epi = useSelector(selectEpi);
	const nodeRef = useRef<HTMLDivElement>(null);

	function handleLinkClick(this: HTMLAnchorElement, event: MouseEvent): void {
		const href = this.getAttribute('href');
		const target = this.getAttribute('target');
		const hasDownloadAttribute = this.hasAttribute('download');
		if (
			href &&
			href.charAt(0) === '/' &&
			target !== '_blank' &&
			!hasDownloadAttribute
		) {
			event.preventDefault();
			history.push(href);
		}
	}

	useEffect(() => {
		const nodeRefRef = nodeRef;
		if (nodeRef.current) {
			nodeRef.current.querySelectorAll('a').forEach((a) => {
				a.addEventListener('click', handleLinkClick);
			});
		}

		return () => {
			if (nodeRefRef.current) {
				nodeRefRef.current.querySelectorAll('a').forEach((a) => {
					a.removeEventListener('click', handleLinkClick);
				});
			}
		};
		// eslint-disable-next-line
	}, []);

	return (
		<div className={className ? className : 'richtext'} ref={nodeRef}>
			{children}
		</div>
	);
};

export default RichText;
