/**
 * BaseLayout
 */

import clsx from 'clsx';
import GoTopButton from 'components/GoTopButton/GoTopButton';
import Meta from 'components/Meta';
import PageFooter from 'components/PageFooter';
import PageHeader from 'components/PageHeader';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectFooter, selectHeader } from 'store/modules/model';
import { ContentTypes } from 'types/pages';

interface Props {
	modelType: ContentTypes['modelType'];
}

/** BaseLayout that adds header and footer to the page. */
const BaseLayout: React.FC<Props> = ({ modelType, children }) => {
	const header = useSelector(selectHeader);
	const footer = useSelector(selectFooter);
	const [height, setHeight] = React.useState(0);

	const handleCallback = (val: number) => {
		setHeight(val + 90); // added 90px bc mountain img on footer
	};

	function setMarginsAndWidth(modelType: string) {
		switch (modelType) {
			case 'LavinStartPage':
			case 'AreaPage':
				return 'main-w-container mx-auto';
			case 'LavinOutsideArea':
				return 'main-w-container mx-auto';
			case 'ErrorPage':
			case 'LavinNavigationPageModel':
			case 'LavinStandardPageModel':
			case 'LavinNewsPageModel':
			case 'LavinNewsListPageModel':
			case 'GlossaryPage':
				return 'max-w-full mt-0';
			default:
				return 'main-w-container mx-auto mt-8 md:mt-8';
		}
	}

	return (
		<>
			<Meta />

			<a className="skipToContent" href="#main">
				{header?.skipToContent}
			</a>

			{header?.menu && header.lang && <PageHeader {...header} />}

			<main
				id="main"
				className={clsx(
					// not nice way to set up margins and width
					setMarginsAndWidth(modelType)
				)}
			>
				{children}
			</main>
			{modelType === 'GlossaryPage' && <GoTopButton footerHeight={height} />}
			<PageFooter {...footer} fnFooterHeight={handleCallback} />
		</>
	);
};

export default BaseLayout;
