/**
 * OutsideAreaPage
 */

import React, { useEffect, useState } from 'react';
import { OutsideAreaPageModel } from 'types/pages';
import { useMediaQuery } from 'react-responsive';
import EpiFragments from 'components/EpiFragments';
import { smoothScrollToTarget } from 'utils/smoothScrollToTarget';
import Map from 'components/Map';
import { Cell, Grid } from 'components/Grid';
import clsx from 'clsx';
import Icon from 'components/Icon';
import { Accordion, AccordionItem } from 'components/Accordion';
import { FragmentModelTypes } from 'types/fragments';
import { pushMatomoEvent } from 'utils/matomoUtils';

/** Page template for OutsideAreaPage. */
const OutsideAreaPage: React.FC<OutsideAreaPageModel> = ({
	heading,
	textNextToMap,
	bodyText,
	map,
	skipMap,
	toc,
}) => {
	const isMobileOrTablet = useMediaQuery({
		maxWidth: 1023,
	});

	const [activeTab, setActiveTab] = React.useState<'map' | 'area'>('area');
	const [hasSmallHeight, setHasSmallHeight] = useState<boolean>();
	const [renderedBodyFragments, setRenderedBodyFragments] = useState<
		JSX.Element[][]
	>();
	const [renderedTopFragments, setRenderedTopFragments] = useState<
		JSX.Element[][]
	>();
	const [tocItems, setTocItems] = useState<any[]>([]);
	// New state to track the ID of the currently active section
	const [activeSection, setActiveSection] = useState<string | null>(null);

	const handleTabChange = (tab: 'map' | 'area') => {
		setActiveTab(tab);
		if (tab === 'map') {
			pushMatomoEvent({
				event: 'klick_mobil_prognossida',
				event_category: 'klick_mobil_prognossida',
				event_action: 'utanför prognosområde',
				event_name: 'kartknapp',
			});
		}
	};

	const handleAccordionToggle = (title: string, isOpen: boolean) => {
		if (isOpen) {
			pushMatomoEvent({
				event: 'klick_block',
				event_category: 'klick_block',
				event_action: 'utanför prognosområde',
				event_name: title,
			});
		}
	};

	useEffect(() => {
		if (typeof window !== 'undefined') {
			setHasSmallHeight(window?.innerHeight < 900);
		}
	}, [hasSmallHeight]);

	// Function to get all "raw" values for "HeadingFragment"
	function getFragments(data: any) {
		const mergingFragments: any = [];
		const fragment =
			data.modelType === 'AccordionBlock' ? (
				<Accordion>
					<AccordionItem title={data.heading} onToggle={handleAccordionToggle}>
						{data.text.fragments.map(
							(fragment: FragmentModelTypes, index: number) => (
								<EpiFragments fragments={[fragment]} key={index} />
							)
						)}
					</AccordionItem>
				</Accordion>
			) : (
				<EpiFragments fragments={[data]} />
			);
		mergingFragments.push(fragment);
		return mergingFragments;
	}

	function processFragments() {
		const mergedBodyFragments = [];
		const mergedTopFragments = [];
		for (let i = 0; i < textNextToMap.fragments.length; i++) {
			const fragmentsSorted = getFragments(textNextToMap.fragments[i]);
			mergedTopFragments.push(fragmentsSorted);
		}
		for (let i = 0; i < bodyText.fragments.length; i++) {
			const fragmentsSorted = getFragments(bodyText.fragments[i]);
			mergedBodyFragments.push(fragmentsSorted);
		}
		setRenderedTopFragments(mergedTopFragments);
		setRenderedBodyFragments(mergedBodyFragments);
	}

	function getAllHeadingsFragment(): void {
		const fragments = bodyText.fragments;
		const headingFragments = fragments.filter(
			(fragment: any) => fragment.modelType === 'HeadingFragment'
		);

		const heading = headingFragments.map((val: any) => val);
		setTocItems(heading);
	}

	useEffect(() => {
		processFragments();
		getAllHeadingsFragment();
	}, []);

	useEffect(() => {
		// Function to check which section is currently active based on scroll position
		function updateActiveSection() {
			const headings = tocItems.map((item) => item.id);
			let currentActiveSection: string = '';

			for (let i = headings.length - 1; i >= 0; i--) {
				const headingElement = document.getElementById(headings[i]);
				if (
					headingElement &&
					headingElement.getBoundingClientRect().top <= 200
				) {
					currentActiveSection = headings[i];
					break;
				}
			}

			if (currentActiveSection !== activeSection) {
				setActiveSection(currentActiveSection);
			}
		}

		// Add scroll event listener to update the active section on scroll
		window.addEventListener('scroll', updateActiveSection);

		// Clean up the event listener when the component is unmounted
		return () => {
			window.removeEventListener('scroll', updateActiveSection);
		};
	}, [tocItems, activeSection]);

	useEffect(() => {
		// Attach click event listeners to each anchor link
		const tocLink = document.querySelectorAll('.table-of-contents');
		tocLink.forEach((link) => {
			link.addEventListener('click', smoothScrollToTarget);
		});

		// Clean up the event listeners when the component is unmounted
		return () => {
			tocLink.forEach((link) => {
				link.removeEventListener('click', smoothScrollToTarget);
			});
		};
	}, []);

	return (
		<div id="outside-area">
			{activeTab === 'area' && isMobileOrTablet && (
				<button
					className="w-16 h-16 bg-grey-light flex flex-col items-center justify-center text-base font-semibold custom-border rounded-md shadow-md
				fixed right-0 top-[134px] z-20
				"
					onClick={() => handleTabChange('map')}
				>
					<Icon icon="map" size={1} className="mr-2 fill-none" />
					<span className="">{'map'}</span>
				</button>
			)}
			{activeTab === 'map' ? (
				<Map
					{...map}
					onCLickBackToArea={handleTabChange}
					isMobile={true}
					isOutsideAreaPage={true}
				/>
			) : (
				<div className="px-6 lg:px-0">
					<h1
						id="outside-area-heading"
						className="mt-10  lg:pb-8 lg:border-b-2 lg:border-grey  font-semibold leading-8"
					>
						{heading}
					</h1>
					<div className="flex justify-start lg:justify-center h-auto lg:mx-auto  mt-4 mb-0 sm:mb-12 flex-wrap md:flex-nowrap px-0 gap-8">
						{!isMobileOrTablet && (
							<div className="flex flex-col">
								{skipMap && (
									<a
										className="skipToContent"
										href="#outside-area-right-section"
									>
										{skipMap}
									</a>
								)}
								<Map {...map} isOutsideAreaPage={true} />
							</div>
						)}
						<section
							className="flex flex-col"
							id="outside-area-right-section"
							aria-labelledby="outside-area-heading"
						>
							{renderedTopFragments}
						</section>
					</div>
					<Grid className="flex-nowrap justify-between mb-80">
						<Cell span={12} desktop={7}>
							{renderedBodyFragments}
						</Cell>

						{!isMobileOrTablet && tocItems.length > 0 && (
							<Cell span={12} desktop={4}>
								<nav
									aria-labelledby="toc-heading"
									className="table-of-contents bg-white rounded-xl border-2 border-grey-light p-6 w-[366px]"
								>
									{toc && (
										<h2
											id="toc-heading"
											className="text-xl font-bold font-standard mb-4"
										>
											{toc}
										</h2>
									)}
									<div className="flex flex-col flex-nowrap items-baseline">
										{tocItems &&
											tocItems.map((item, i) => {
												// Check if the current option is selected
												const isSelected = item.id == activeSection;
												return (
													<a
														aria-label={item.raw} // Later toc will have seprate object and headeing instead of raw
														key={i.toString()}
														href={`#${item.id}`}
														className={clsx(
															'text-lg cursor-pointer h-auto',
															'px-6 py-1',
															isSelected
																? 'border-l-8 border-orange-400 selected'
																: 'border-l-8 border-grey'
														)}
														onClick={smoothScrollToTarget}
														dangerouslySetInnerHTML={{ __html: item.raw }}
													/>
												);
											})}
									</div>
								</nav>
							</Cell>
						)}
					</Grid>
				</div>
			)}
		</div>
	);
};

export default OutsideAreaPage;
