import { ContentTypes } from 'types/pages';
/* PLOP_INJECT_IMPORT */
import GlossaryPage from './GlossaryPage';
import NewsListPage from './NewsListPage';
import NavigationPage from './NavigationPage';
import AreaPage from './AreaPage';
import ErrorPage404 from './ErrorPage404';
import ForecastListPage from './ForecastListPage';
import FormContainerPage from './FormContainerPage';
import PreviewBlockPage from './PreviewBlockPage';
/* PLOP_INJECT_IMPORT */
import StandardPage from './StandardPage';
import NewsPage from './NewsPage';
import StartPage from './StartPage';
import OutsideAreaPage from './OutsideAreaPage';

const Pages: any = {
	LavinStartPage: StartPage,
	ErrorPage404,
	/* PLOP_INJECT_PAGE */
	GlossaryPage,
	LavinNewsListPageModel: NewsListPage,
	LavinNavigationPageModel: NavigationPage,
	AreaPage,
	ForecastListPage,
	LavinStandardPageModel: StandardPage,
	LavinNewsPageModel: NewsPage,
	LavinOutsideArea: OutsideAreaPage,
	FormContainerPage,
	PreviewBlockPage,
};

export const PagesMapper = (pageContent: ContentTypes | undefined) => {
	if (pageContent) {
		let page: any = Pages[pageContent.modelType];

		if (page) {
			return page;
		}
	}

	return ErrorPage404;
};
