type MatomoEvent = {
	event: string;
	event_category: string;
	event_action: string;
	event_name: string;
};

export const pushMatomoEvent = (eventData: MatomoEvent) => {
	if (typeof window !== 'undefined' && Array.isArray((window as any)._mtm)) {
		(window as any)._mtm.push(eventData);
	} else {
		console.error('Matomo _mtm object is not available.');
	}
};
