import axios from 'axios';
import { WMTSCapabilities } from 'ol/format';
export const fetchWMTSCapabilities = async (
	url: string
): Promise<WMTSCapabilities> => {
	const response = await axios.get(url);
	if (response.status !== 200) {
		throw new Error('Failed to fetch WMTS capabilities');
	}
	return new WMTSCapabilities().read(response.data);
};
