/**
 * MobileAreaPage
 */

import Forecast from 'components/Forecast';
import Map from 'components/Map';
import MoreInformationBlock from 'components/MoreInformationBlock';
import AreaHeader from 'pages/AreaPage/components/AreaHeader';
import React, { useState } from 'react';
import { MoreInformationBlockModel } from 'types/blocks';
import { MapModel } from 'types/epi';
import { Forecast as ForecastType } from 'types/pageTypes/area_page';
import { Link as LinkCustom } from 'types/global';
import Icon from 'components/Icon';
import { pushMatomoEvent } from 'utils/matomoUtils';

interface Props {
	/** Description of this property */
	areaId: number;
	map: MapModel;
	forecast: ForecastType;
	forecastDateText: string;
	seePreviousForecastText: string;
	selectedDate: string;
	moreInformationBlock: MoreInformationBlockModel | undefined;
	activeTab: 'map' | 'area';

	/* area header props */
	heading: string;
	areaName: string;
	historical: {
		isHistorical: boolean;
		content: string;
		link: Omit<LinkCustom, 'target' | 'active' | 'newTab'>;
	};
	previewControls?: {
		previewButton: {
			url: string;
			text: string;
		};
		nextButton: {
			url: string;
			text: string;
		};
		previousButton: {
			url: string;
			text: string;
		};
	};
	avalancheHazardText: string;
	avalancheScaleLink: LinkCustom;
	mobileMapLabel: string;
}

/** Main description for this component. */
const MobileAreaPage: React.FC<Props> = ({
	areaId,
	map,
	forecast,
	forecastDateText,
	seePreviousForecastText,
	selectedDate,
	moreInformationBlock,
	//activeTab,
	heading,
	areaName,
	historical,
	previewControls,
	avalancheHazardText,
	avalancheScaleLink,
	mobileMapLabel,
}) => {
	const [activeTab, setActiveTab] = React.useState<'map' | 'area'>('area');
	const handleTapTab = (tab: 'map' | 'area') => {
		setActiveTab(tab);
		if (tab === 'map') {
			pushMatomoEvent({
				event: 'klick_mobil_prognossida',
				event_category: 'klick_mobil_prognossida',
				event_action: 'prognosområde',
				event_name: 'kartknapp',
			});
		}
	};

	return (
		<div className="relative flex flex-col">
			{activeTab === 'area' && (
				<button
					className="w-16 h-16 bg-grey-light flex flex-col items-center justify-center text-base font-semibold custom-border rounded-md shadow-md
				fixed right-0 top-[118px] z-20
				"
					onClick={() => handleTapTab('map')}
				>
					<Icon icon="map" size={1} className="mr-2 fill-none" />
					<span className="">{mobileMapLabel}</span>
				</button>
			)}
			{activeTab === 'map' ? (
				<Map
					{...map}
					areaId={areaId}
					onCLickBackToArea={handleTapTab}
					isMobile={true}
					isAreaPage={true}
				/>
			) : (
				<div className="pb-20 px-6">
					<AreaHeader
						heading={heading}
						areaName={areaName}
						forecast={forecast}
						historical={historical}
						previewControls={previewControls}
						avalancheHazardText={avalancheHazardText}
						avalancheScaleLink={avalancheScaleLink}
						isMobile={true}
					/>
					<Forecast
						forecast={forecast}
						forecastDateText={forecastDateText}
						seePreviousForecastText={seePreviousForecastText}
						selectedDate={selectedDate}
					/>
					{moreInformationBlock && (
						<MoreInformationBlock
							{...moreInformationBlock}
							linkSize={'small'}
						/>
					)}
				</div>
			)}
		</div>
	);
};

export default MobileAreaPage;
