/**
 * AccordionItem
 */

import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';

import Image from 'components/Image';

export interface AccordionItemProps {
	/** The title/heading text for the accordion item. */
	title: string;

	/** Subtitle for accordion */
	subtitle?: string;

	/** Should the item be opened by default? */
	open?: boolean;

	/** Tag type to set the header to */
	as?: any;

	/** Callback when the item is toggled */
	onToggle?: (title: string, isOpen: boolean) => void;

	/** Accordion id (if not set, a unique id is generated) */
	id?: string;

	/** Optional side image (rounded) */
	image?: any;

	/** boolean if is Risk scale data */
	isRiskScale?: boolean;

	/** Optional background color (risk mobile table) */
	backgroundColor?: string;

	/** Optional text color color (risk mobile table) */
	textColor?: string;
}

/** A single item inside an accordion. */
const AccordionItem: React.FC<AccordionItemProps> = ({
	title,
	subtitle,
	image,
	children,
	onToggle,
	open = false,
	as = 'h2',
	id,
	isRiskScale = false,
	backgroundColor,
	textColor,
}) => {
	const Heading = as;
	const detailsRef = useRef<HTMLDetailsElement | null>(null);

	useEffect(() => {
		const node = detailsRef.current;
		if (node) {
			const handleToggle = () => {
				onToggle && onToggle(title, node.open);
			};

			node.addEventListener('toggle', handleToggle);

			return () => {
				node.removeEventListener('toggle', handleToggle);
			};
		}
	}, [onToggle, title]);

	return (
		<details
			ref={detailsRef}
			className={clsx(
				'accordion-item first:mt-0 -mt-1',
				isRiskScale && 'risk-scale'
			)}
		>
			<summary
				className={clsx(
					'border-grey-light border-t-2 mb-1 px-0 focus:outline flex relative',
					!isRiskScale && (image ? 'py-5' : 'py-8')
				)}
			>
				{!isRiskScale && (
					<>
						{image && (
							<div className="flex sm:ml-4">
								<Image
									src={image.src}
									alt={image.alt ? image.alt : ''}
									className="w-12 h-12 rounded-3xl border-1 border-grey border-solid mr-6"
								/>
								<div className="flex flex-col text-left">
									<span className="text-grey-darker text-h5 font-semibold">
										{title}
									</span>

									{subtitle && (
										<Heading className="text-h4 font-bold text-orange">
											{subtitle}
										</Heading>
									)}
								</div>
							</div>
						)}

						{!image && (
							<Heading className="text-h4 font-bold text-black sm:ml-6">
								{title}
							</Heading>
						)}
					</>
				)}
				{isRiskScale && (
					<>
						{image && (
							<div className="flex h-20 justify-center items-center">
								<Image
									src={image.src}
									alt={image.alt}
									className="w-12 h-12 rounded-3xl border-1 border-grey border-solid mr-6"
								/>

								<div
									className="flex text-left flex-1 w-60 h-full justify-center items-center"
									style={{
										background: backgroundColor ? backgroundColor : '',
									}}
								>
									<span
										className="text-h5 font-semibold mr-4"
										style={{
											color: textColor ? textColor : '',
										}}
									>
										{title}
									</span>
									<span
										className="text-h5 font-semibold"
										style={{
											color: textColor ? textColor : '',
										}}
									>
										{subtitle}
									</span>
								</div>
							</div>
						)}

						{!image && (
							<span className="text-h4 font-bold text-black sm:ml-6">
								{title}
							</span>
						)}
					</>
				)}
			</summary>
			<div className="accordion-item-content py-2">{children}</div>
		</details>
	);
};

export default AccordionItem;
