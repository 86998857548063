/**
 * Legend
 */

import React, { useEffect } from 'react';
import { LinkWithImage } from 'types/global';
import MoreInformationBlock from 'components/MoreInformationBlock';
import Icon from 'components/Icon';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { Cell, Grid } from 'components/Grid';
import Link from 'components/Link';
import Image from 'components/Image';

interface Props {
	/** Used to see if the Legend Sider should be visible or not */
	isVisible?: boolean;

	/* callback to close the modal */
	hide: () => void;

	/** Legend heading */
	heading: string;

	/** Legend description */
	description?: string;

	/** Legend links */
	links: LinkWithImage[];

	/** Legend links heading */
	linksHeading?: string;
}

/** Custom Modal of Legend. */
const Legend: React.FC<Props> = ({
	isVisible = false,
	heading,
	description,
	links,
	linksHeading,
	hide,
}) => {
	const dialogRef = React.useRef<HTMLDialogElement>(null);
	const closeButtonRef = React.useRef<HTMLButtonElement>(null);
	const lastLinkRef = React.useRef<HTMLAnchorElement>(null);
	const firstLinkRef = React.useRef<HTMLAnchorElement>(null);

	const isMobile = useMediaQuery({
		maxWidth: 767,
	});

	useEffect(() => {
		if (isVisible && dialogRef.current) {
			dialogRef.current.focus();
		}
	}, [isVisible]);

	const handleContainerKeyDownPress = (
		event: React.KeyboardEvent<HTMLDialogElement>
	) => {
		switch (event.key) {
			case 'Escape':
				event.preventDefault();
				event.stopPropagation();
				hide();
				break;
			case 'Tab':
				if (!event.shiftKey && document.activeElement === lastLinkRef.current) {
					if (closeButtonRef && 'current' in closeButtonRef) {
						closeButtonRef?.current?.focus();
						event.preventDefault();
					}
				} else if (
					event.shiftKey &&
					document.activeElement === closeButtonRef.current
				) {
					if (lastLinkRef && 'current' in lastLinkRef) {
						lastLinkRef?.current?.focus();
						event.preventDefault();
					}
				}

			default:
				break;
		}
	};

	return (
		<>
			{isVisible && (
				<dialog
					ref={dialogRef}
					className="overflow-y-auto overflow-x-hidden fixed inset-0 z-20 bg-grey-darkest bg-opacity-75 justify-center items-baseline sm:items-center flex outline-none focus:outline-none"
					aria-modal="true"
					role="dialog"
					onKeyDown={handleContainerKeyDownPress}
				>
					<div className="relative mx-4 sm:mx-auto legend-modal bg-white shadow h-auto">
						<div className={clsx('mb-4', 'px-0 lg:p-4 bg-white mt-10 sm:mt-0')}>
							<div
								className={clsx(
									!isMobile && ' mb-8',
									isMobile && ' mb-4',
									'w-full flex justify-between flex-col items-start pb-8 '
								)}
							>
								<div
									className={clsx(
										'w-full mb-3 mt-0 sm:mt-4 flex',
										isMobile ? 'flex-col' : 'justify-between flex-row-reverse'
									)}
								>
									<div className={clsx(isMobile && 'flex justify-end')}>
										<button
											ref={closeButtonRef}
											type="button"
											className="flex items-center justify-center px-2 min-w-menu-btn h-10 hover:bg-gray-200 focus:outline-primary outline-reverse-offset-1 rounded-md uppercase bg-grey"
											onClick={hide}
										>
											<span className="sr-only">Stäng {heading}</span>
											<Icon size={1} icon="remove" aria-hidden="true" />
										</button>
									</div>
									<h2
										id="more-info-block-heading"
										className="text-black text-h4 leading-5 font-semibold"
									>
										<Icon
											size={isMobile ? 1 : 3}
											icon="circleExclamation"
											aria-hidden="true"
											direction="left"
											className="mr-4 -mt-2"
										/>

										{heading}
									</h2>
								</div>

								{description && (
									<p className="max-w-xl font-standard text-base mr-2 leading-6">
										{description}
									</p>
								)}
							</div>
							<h2
								id="more-info-block-link-heading"
								className="text-black text-2.5xl leading-7 font-semibold mb-8"
							>
								{linksHeading}
							</h2>
							<Grid padding={false}>
								{links &&
									links.map((link: LinkWithImage, index: number) => {
										return (
											<Cell
												span={12}
												tablet={6}
												key={'link-' + index}
												className={clsx('md:mr-8')}
											>
												<Link
													href={link.url}
													ref={
														index === links.length - 1
															? lastLinkRef
															: index === 0
															? firstLinkRef
															: null
													}
													className={clsx(
														'shadow-card flex flex-wrap items-center rounded-lg bg-white no-underline hover:shadow-md group',
														'md:h-card mb-2'
													)}
												>
													<div className={clsx('bg-grey-light', 'w-20 h-card')}>
														<Image
															src={link.image.src}
															//srcSet={link.image.srcSet}
															//srcSetSizes={link.image.srcSetSizes}
															alt={link.image.alt}
															loading="lazy"
															className="w-full h-full object-cover"
														/>
													</div>

													<div className="flex justify-between flex-1 items-center ml-3">
														<h3
															id={`link-with-image-block-title-${index}`}
															className={clsx(
																'no-underline font-bold font-standard group-hover:underline',

																'text-base leading-5'
															)}
														>
															{link.heading}
														</h3>

														<Icon
															size={1}
															icon="chevron"
															direction="left"
															className="mr-5"
														/>
													</div>
												</Link>
											</Cell>
										);
									})}
							</Grid>
						</div>
					</div>
				</dialog>
			)}
		</>
	);
};

export default Legend;
