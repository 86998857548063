import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getEpiPage } from 'api/epiApi';
import { AppThunk, RootState } from 'store';
import { Model } from 'types/epi';
import { ContentTypes } from 'types/pages';

interface ModelState {
	model: Model;
	error: string | null;
	loading: boolean;
	isOpen: boolean;
}

const initialState: ModelState = {
	error: null,
	loading: false,
	model: {
		content: undefined,
	},
	isOpen: false,
};

const slice = createSlice({
	name: 'model',
	initialState,
	reducers: {
		getModelStart: (state) => {
			state.loading = true;
		},
		getModelSuccess: (state, action: PayloadAction<Model>) => {
			state.model = action.payload;
			state.error = null;
			state.loading = false;
		},
		getModelFailed: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
			state.loading = false;
		},
		updateContent: (state, action: PayloadAction<ContentTypes>) => {
			state.model.content = action.payload;
		},
		openMenu: (state) => {
			state.isOpen = true;
		},
		closeMenu: (state) => {
			state.isOpen = false;
		},
		toogleMenu: (state) => {
			state.isOpen = !state.isOpen;
		},
	},
});

export const fetchPage = (
	routeApiUrl: string,
	route: string
): AppThunk => async (dispatch) => {
	let data;
	dispatch(getModelStart());

	try {
		data = await getEpiPage(routeApiUrl, route);
	} catch (err) {
		dispatch(getModelFailed(err.toString()));
		return;
	}

	dispatch(getModelSuccess(data));
};

export const selectMenuIsOpen = (state: RootState) => state.model.isOpen;
export const selectModel = (state: RootState) => state.model;
export const selectHeader = (state: RootState) => state.model.model.header;
export const selectBreadcrumbs = (state: RootState) =>
	state.model.model.breadcrumbs;
export const selectLocalization = (state: RootState) =>
	state.model.model.localization;
export const selectContent = (state: RootState) => state.model.model.content;
export const selectMap = (state: RootState) => state.model.model.map;
export const selectFooter = (state: RootState) => state.model.model.footer;
export const selectMetaData = (state: RootState) => state.model.model.metadata;
export const {
	getModelStart,
	getModelSuccess,
	getModelFailed,
	updateContent,
	openMenu,
	closeMenu,
	toogleMenu,
} = slice.actions;

export default slice.reducer;
